<!-- 交付阶段 -->
<template>
    <div v-loading="loading" @click="resizedChange" ref="MyChartDom" class="my_tasks">
        <!-- <div class="arrow">
            <el-icon color="#fff" :size="20" @click="move('left')">
                <ArrowLeftBold />
            </el-icon>
        </div>
        <div class="arrow arrow-right">
            <el-icon color="#fff" :size="20" @click="move('right')">
                <ArrowRightBold />
            </el-icon>
        </div> -->
        <!-- <div v-if="ischart" class="omit">...</div> -->
        <div class="my_tasks_title">交付阶段5/6</div>
        <div class="my_tasks_item_wrap" :style="{ left: num + 'px' }">
            <div class="item-row" v-for="(item, i) in list" :key="i">
                <div class="item-box" :style="{ border: '1px solid', borderColor: i === 1 ? 'red' : '#ccc' }">
                    <div class="children" v-for="val in item.children" :key="val">
                        <div class="content" :style="{ color:parseInt(val.value) === 0 ? '#fff' : val.color }">
                            {{ val.name }}
                            <span @click="goUrl(val.url,val.value)" :style="{ color:parseInt(val.value) === 0 ? '#fff' : val.color }">{{ val.value }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <span v-show="isShow !== '1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import { deliveryStage } from "@/api/ChartsDataRequest";
// import { ElMessage } from 'element-plus'

export default {
    name: "terminated_customer",
    props: [],
    emits: ["closeChart"],
    data() {
        return {
            num: 0,
            loading: true,
            isShow: '',
            w: '100%',
            // ischart: true,
            list: [
                {
                    children: [
                        {
                            name: '备料中：',
                            url: '/customerManage/stationManagement?work_status=3',
                            value: '',
                        },
                        {
                            name: '内部模拟：',
                            url: '/customerManage/stationManagement?work_status=4',
                            value: '',
                        },
                        {
                            name: '总计模拟已退库：',
                            url: '/customerManage/stationManagement?work_status=6',
                            value: '',
                        },
                        {
                            name: '30天内模拟已退库：',
                            url: '/customerManage/stationManagement?jump_type=9',
                            value: '',
                        },
                        {
                            name: '等待出货：',
                            url: '/customerManage/stationManagement?work_status=18',
                            value: '',
                        },
                        {
                            name: '等待部署：',
                            url: '/customerManage/stationManagement?work_status=7',
                            value: '',
                        },
                        {
                            name: '现场部署中：',
                            url: '/customerManage/stationManagement?work_status=8',
                            value: '',
                        },
                        {
                            name: '总计部署异常已退机：',
                            url: '/customerManage/stationManagement?work_status=10',
                            value: '',
                        },
                        {
                            name: '30天内部署异常已退机：',
                            url: '/customerManage/stationManagement?jump_type=10',
                            value: '',
                        },
                    ]
                },
                {
                    children: [
                        //超期
                        {
                            name: '备料超期：',
                            url: '/customerManage/stationManagement?jump_type=4',
                            value: '',
                            color: '#FF0000',
                        },
                        {
                            name: '模拟超期：',
                            url: '/customerManage/stationManagement?jump_type=5',
                            value: '',
                            color: '#FF0000',
                        },
                        {
                            name: '出货超期：',
                            url: '/customerManage/stationManagement?jump_type=6',
                            value: '',
                            color: '#FF0000',
                        },
                        {
                            name: '部署超期：',
                            url: '/customerManage/stationManagement?jump_type=7',
                            value: '',
                            color: '#FF0000',
                        },
                        //预警
                        {
                            name: '备料超期预警：',
                            url: '/customerManage/stationManagement?jump_type=15',
                            value: '',
                            color: '#F56C6C',
                        },
                        {
                            name: '模拟超期预警：',
                            url: '/customerManage/stationManagement?jump_type=16',
                            value: '',
                            color: '#F56C6C',
                        },
                        {
                            name: '出货超期预警：',
                            url: '/customerManage/stationManagement?jump_type=17',
                            value: '',
                            color: '#F56C6C',
                        },
                        {
                            name: '部署超期预警：',
                            url: '/customerManage/stationManagement?jump_type=18',
                            value: '',
                            color: '#F56C6C',
                        }
                    ]
                }
            ],
        };
    },
    methods: {
        // 图表关闭
        handleCloseChart() {
            this.$emit("closeChart");
        },

        // 图表创建
        createChart() {
            this.loading = false;
            deliveryStage().then((res) => {
                if (res.data.code == 200) {
                    this.list.forEach(item => {
                        item.children.forEach(v => {
                            if (v.name === '备料中：') {
                                v.value = res.data.data.prepare_material
                            } else if (v.name === '内部模拟：') {
                                v.value = res.data.data.inner_simulate
                            } else if (v.name === '总计模拟已退库：') {
                                v.value = res.data.data.inner_simulate_back_store
                            } else if (v.name === '等待出货：') {
                                v.value = res.data.data.wait_send_product
                            } else if (v.name === '等待部署：') {
                                v.value = res.data.data.wait_deploy
                            } else if (v.name === '现场部署中：') {
                                v.value = res.data.data.deploying
                            } else if (v.name === '总计部署异常已退机：') {
                                v.value = res.data.data.deploy_question_back
                            } else if (v.name === '备料超期：') {
                                v.value = res.data.data.prepare_material_delay
                            }  else if (v.name==='备料超期预警：') {
                                v.value = res.data.data.prepare_material_warn
                            } else if (v.name === '模拟超期：') {
                                v.value = res.data.data.inner_simulate_delay
                            } else if (v.name === '模拟超期预警：') {
                                v.value = res.data.data.inner_simulate_warn
                            } else if (v.name === '出货超期：') {
                                v.value = res.data.data.send_product_delay
                            } else if (v.name === '出货超期预警：') {
                                v.value = res.data.data.send_product_warn
                            } else if (v.name === '部署超期：') {
                                v.value = res.data.data.deploy_delay
                            }else if (v.name === '部署超期预警：') {
                                v.value = res.data.data.deploy_warn
                            } else if (v.name === '30天内模拟已退库：') {
                                v.value = res.data.data.inner_simulate_back_store_30day
                            }else if (v.name === '30天内部署异常已退机：') {
                                v.value = res.data.data.deploy_question_back_30day
                            }
                        })
                    })

                } else {
                    console.log("Robot Data request fail");
                }
                this.loading = false;
            });
        },
        // 跳转
        goUrl(row,value) {
            if(parseInt(value)!==0) window.$wujie.props.method.propsMethod(row)
      
        },
        // move(type) {
        //     const visual_w = this.$refs['MyChartDom'].offsetWidth
        //     if (type === 'left') {
        //         if (parseInt(this.num) === 0) {
        //             ElMessage.success('已是第一个')
        //             return false
        //         }
        //         this.num = (Number(this.num) + Number(visual_w))
        //     } else {
        //         if (Math.abs(this.num) >= parseInt(this.w) - parseInt(visual_w)) {
        //             ElMessage.success('已是最后一个')
        //             console.log('已是最后一个', this.num);
        //             return false
        //         }
        //         this.num = (Number(this.num) - Number(visual_w))
        //     }
        // },
        initial() {
            // const visual_w = this.$refs['MyChartDom'].offsetWidth
            // if (visual_w > 500) {
            //     this.ischart = false
            // } else {
            //     if (visual_w < 400) {
            //         this.ischart = false
            //     } else {
            //         this.ischart = true
            //     }
            // }
        },
        resizedChange() {
            this.initial()
        }
    },
    mounted() {
        this.createChart();
        this.isShow = sessionStorage.getItem("isShow")
    },
};
</script>

<style lang="scss" scoped>
.chart-drobot-title {
    color: #fff;
    font-size: 18px;
    padding-top: 8px;
}

.my_tasks {
    overflow: hidden;
    width: 100%;
    height: 100%;

    .arrow {
        position: absolute;
        left: 0;
        height: 80%;
        bottom: 0;
        display: flex;
        align-items: center;
        z-index: 10;
        cursor: pointer;
        background-color: #143230;
    }

    .arrow-right {
        left: auto;
        right: 0;
    }

    // 省略
    .omit {
        color: #fff;
        font-size: 25px;
        position: absolute;
        left: 76%;
        height: 80%;
        bottom: 0;
        display: flex;
        align-items: center;
        z-index: 10;
        cursor: pointer;
    }

    .my_tasks_title {
        font-size: 20px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height:68px;
        background-color: rgba(255, 255, 255, 0.08);
        color: #fff;

    }

    .my_tasks_item_wrap {
        width: v-bind(w);
        height: 80%;
        text-align: left;
        align-items: center;
        transition: all 0.1s ease-in-out;
        position: relative;

        .item-row {
            width: 330px;
            height: 100%;
            margin-left: 20px;

            .item-box {
                width: 100%;
                height: 90%;
                display: flex;
                flex-direction: column;
                align-items: center;
                border: 1px solid #ccc;
                border-radius: 15px;
                font-size: 1.4rem;
                color: #fff;
                margin-top: 10px;

                .children {
                    display: block;

                    .content {
                        margin-top: 20px;

                        span {
                            color: #f9b707;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}
</style>
